import styled from "styled-components";
import { ThreeDots } from "../../components/icons/ThreeDots";
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { theme } from "../../theme-colors/theme";
import { useLocation } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        color: theme.lightColor,
        py: 4,
        bgcolor: theme.textColor
      }}
    >
      <Container maxWidth="lg"
        sx={{
          backgroundColor: theme.textColor
        }}>
        <Box sx={{
          display: { xs: "none", sm: "flex" },
          justifyContent: "space-around"
        }}>
          <Box sx={{
            flexDirection: "column"
          }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  About Us
                </Typography>
                <Box>
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    SparkBird Korea
                  </Link>
                  <br />
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    Contact Us
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  Support
                </Typography>
                <Box>
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    FAQ
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }} >
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  Terms
                </Typography>
                <Box>
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    Terms of Use
                  </Link>
                  <br />
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    Privacy Policy
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <Box textAlign="left" mt={4}>
              <Typography variant="overline" color="inherit">
                Copyright &copy; {new Date().getFullYear()} SparkBird Korea All rights reserved.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}>
            <img
              src="/store/logobig_w.png"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
        </Box>
      </Container >
    </Box >
  );
}
export default Footer;
