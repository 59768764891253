import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import Main from "./Main";
import About from "./cores/About";
import Root from "./Root";
// import Explore from "./cores/Explore";
import Tour from "./cores/Tour";
import Reservation from "./cores/Reservation";
import Contact from "./cores/Contact";
import TourPost from "./pages/tour/TourPost";
import Uploader from "./pages/uploader/Uploader";

const router = createBrowserRouter(
  [
    {
      // 전체 route들의 컴포넌트 같은 것
      path: "/",
      element: <Root />,
      children: [
        {
          path: "",
          element: <Main />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "tour",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Tour />,
            },
            {
              path: ":tourId",
              element: <TourPost />,
            },
          ],
        },
        {
          path: "tour",
          element: <About />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "reservation",
          element: <Reservation />,
        },
        {
          path: "admin-uploader",
          element: <Uploader />,
        },
      ],
      // errorElement: ,
    },
  ],
  { basename: `${process.env.PUBLIC_URL}` }
);

export default router;
