import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IBird, getBirdById, getImageById, getTopBirdById } from "../../../../api/exploreApi";
import { readImageFromS3 } from "../../../../functions/readImageFromS3";
type BirdCardProps = {
    topBirdId: string;
};

export default function BirdCard({ topBirdId: birdId }: BirdCardProps) {

    const { isLoading: birdLoading, data: birdData } = useQuery<IBird>({
        queryKey: ["birdcard" + birdId],
        queryFn: () => getBirdById(birdId),
    });
    const { isLoading: imgLoading, data: images } = useQuery<string[]>({
        queryKey: ["image" + birdId],
        queryFn: () => getImageById(birdId),
    });

    const onCardClick = () => {
        window.open(birdData?.link, '_blank');
    };
    return (
        <Box component="div" onClick={onCardClick}
            sx={{
                '&:hover': {
                    cursor: 'pointer',
                },
            }}>
            <AspectRatio
                variant="outlined"
                ratio="4/3"
                sx={{ width: 300, bgcolor: 'background.level2', display: 'inline-block' }}
            >
                <img alt="" src={readImageFromS3(images != null && images.length > 0 ? images[0] : "logo/logo_w.png")} />
            </AspectRatio>
            <Typography fontFamily='MazzardM-Medium'>
                {birdData?.name}
            </Typography>
        </Box>
    );

}