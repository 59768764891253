import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";

import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import { theme } from "../../../theme-colors/theme";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { useState } from "react";
import { Typography } from "@mui/material";
import { ITour, DateRange } from "../../../api/exploreApi";

export default function ReserveCard(tour: ITour) {
  const [count, setCount] = useState(0);
  return (
    <Box sx={{ justifyContent: 'right', display: 'flex', position: 'sticky', top: 10, }}>
      <Card sx={{ width: "90%", backgroundColor: theme.lightColor, }}>
        <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">${tour.price} per person</Typography>
        <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">Select date options</Typography>
        <Stack spacing={2} alignItems="flex-start">
          <Select placeholder="Date" name="foo" required sx={{ width: "100%" }}>
            {tour.dates.dates?.map((dates: DateRange) => (
              <Option value={dates.startDate}>{dates.startDate} ~ {dates.endDate}</Option>
            ))}
          </Select>
        </Stack>
        <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">Select travelers</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => {
              if (count > 1)
                setCount((c) => c - 1)
            }}
          >
            <Remove />
          </IconButton>
          <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">
            {count}
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => {
              if (count < tour.size)
                setCount((c) => c + 1)
            }}
          >
            <Add />
          </IconButton>
        </Box>

        <CardContent orientation="horizontal">
          <div>
            <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">Total price:</Typography>
            <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">
              ${tour.price * count}
            </Typography>
          </div>
          <Button
            variant="solid"
            type="submit"
            size="md"
            aria-label="Explore Bahamas Islands"
            sx={{ ml: "auto", alignSelf: "center", fontFamily: "MazzardM-Medium" }}
            style={{
              backgroundColor: theme.btnColor,
              color: theme.backgroundColor,
            }}
          >
            Reservation
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}
