import styled from "styled-components";
import { theme } from "../../theme-colors/theme";
import { AddressIcon } from "../../components/icons/AddressIcon";
import { EmailIcon } from "../../components/icons/EmailIcon";
import { PhoneIcon } from "../../components/icons/PhoneIcon";
import {
  ColumnContainer,
  RowStack,
  ColumnStack,
} from "../../components/common/Container";
import { Paragraph, Title } from "../../components/common/Text";
import { HorizontalLine } from "../../components/common/Shape";
import Typography from "@mui/material/Typography";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
interface IField {
  width: string;
  height: string;
}
const Field = styled.input<IField>`
  border-color: ${(props) => props.theme.textColor};
  border-style: solid;
  border-width: 2px;
  margin: 10px 0px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media (max-width: 950px) {
    font-size: 8px;
    width: ${(props) => Number.parseInt(props.width) / 1.5}px;
  }
`;
const SubmitButton = styled.button`
  @media (max-width: 950px) {
    font-size: 8px;
    height: 20px;
    margin: 30px;
    width: ${500 / 1.5}px;
  }
  font-size: 15px;
  width: 500px;
  height: 40px;
  background-color: ${theme.grayColor};
  border: 0;
  color: ${theme.textColor};
  margin: 30px;
`;
const ContactInfo = styled.div`
  width: 100%;
  color: ${(props) => props.theme.backgroundColor};
  @media (max-width: 950px) {
    font-size: 9px;
  }
  font-size: 18px;
  font-weight: 200;
  padding-top: 10px;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

function Contact() {
  return (
    <ColumnContainer>
      <RowStack paddingBottom="100px">
        <ColumnStack minWidth="500px">
          <Title>Contact us!</Title>
          <HorizontalLine />
          <Typography
            sx={{
              wordWrap: "break-word",
              fontSize: "18px",
            }}
            fontFamily='MazzardM-Medium'
          >
            You can reach us via phone or email.
            <br />
            <br />
            Due to our location in South Korea,
            <br />
            phone communication may be difficult,
            <br />
            but feel free to email us anytime.
            <br />
            <br />
            We guarantee a response within 24 hours.
            <br />
            <br />
            Thank you for considering contacting us.
            <br />
            We look forward to hearing from you soon!
          </Typography>
          <HorizontalLine />
        </ColumnStack>

        <ColumnStack>
          <Form>
            <RowStack
              style={{
                padding: 0,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Field placeholder="Name" width="240px" height="30px"></Field>
              <Field placeholder="E-mail" width="240px" height="30px"></Field>
            </RowStack>
            <Field placeholder="Phone" width="500px" height="30px"></Field>
            <Field placeholder="Message" width="500px" height="250px"></Field>
          </Form>
          <SubmitButton>Send Email</SubmitButton>
        </ColumnStack>
      </RowStack>

      <ColumnStack
        paddingBottom="100px"
        style={{ backgroundColor: theme.grayColor }}
      >
        <Title>Frequent Questions?</Title>
        <HorizontalLine />
        <ColumnStack></ColumnStack>
      </ColumnStack>
    </ColumnContainer>
  );
}
export default Contact;
