import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IBird, ICreateBird, IMonth, createBird, getAllBirds, getAllMonths } from "../../../api/exploreApi";

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import { theme } from "../../../theme-colors/theme";
import BirdList from "./BirdList";

function BirdUploader() {

  const { isLoading: birdLoading, data: fetchBirdData, refetch } = useQuery<IBird[]>({
    queryKey: ["birdsAll"],
    queryFn: () => getAllBirds(),
  });
  const { isLoading: monthLoading, data: monthData } = useQuery<IMonth[]>({
    queryKey: ["month"],
    queryFn: () => getAllMonths(),
  });
  interface ImagePreview {
    file: File;
    url: string;
  }

  const {
    register, // inpur 값을 훅에 등록해줍니다.
    handleSubmit, // onSubmit 실행 전에 검증해줍니다.
    watch, // form의 입력값들의 변화를 관찰할 수 있게 해줍니다.
    reset,
    control,
    formState: { isSubmitSuccessful },
  } = useForm<ICreateBird>({
  });


  const onSubmit = (data: ICreateBird) => {
    data.files = selectedImages;
    data.content = editorContentRef.current?.getInstance().getMarkdown() || "";
    data.content = data.content.replaceAll("\n", "  \n");


    data.summary = editorSummaryRef.current?.getInstance().getMarkdown() || "";
    data.summary = data.summary.replaceAll("\n", "  \n");

    const fetchNewBirds = async () => {
      const newBirdData = await createBird(
        data
      );
      refetch();
    };
    fetchNewBirds();
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful])

  // 이미지 업로드와 삭제 
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<ImagePreview[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      if (files.length > 5) {
        alert('파일은 최대 5개까지 업로드 할 수 있습니다.');
        return;
      }
      const selectedFiles = Array.from(files).slice(0, 5); // 최대 5개의 파일만 선택
      setSelectedImages(selectedFiles);

      const newPreviewUrls = selectedFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file)
      }));
      setPreviewUrls(newPreviewUrls);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newSelectedImages = selectedImages.filter((_, i) => i !== index);
    const newPreviewUrls = previewUrls.filter((_, i) => i !== index);
    setSelectedImages(newSelectedImages);
    setPreviewUrls(newPreviewUrls);

    // 파일 입력 요소를 초기화
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    // 파일 입력 요소를 다시 설정하여 개수를 업데이트
    const dataTransfer = new DataTransfer();
    newSelectedImages.forEach((file) => dataTransfer.items.add(file));
    if (fileInputRef.current) {
      fileInputRef.current.files = dataTransfer.files;
    }
  };

  // 컨텐츠 글 입력
  const editorContentRef = useRef<Editor>(null);
  const editorSummaryRef = useRef<Editor>(null);

  // 월별
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

  return (
    <>
      <div>
        <form
          style={{ display: "flex", flexDirection: "column", width: "500px", gap: "10px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <text>새 이름</text>
          <input {...register("name", { required: "새 이름 필수 작성" })} placeholder="bird's name" style={{ height: "30px" }} />

          <text>시기</text>
          <FormGroup aria-multiselectable="true">
            {monthData?.map((month) => (
              <Controller
                key={month.monthId}
                name="monthIdList"
                control={control}
                render={({ field }) => {
                  const currentValue = field.value || [];
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          value={month.monthId}
                          checked={currentValue.includes(month.monthId)}
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? [...currentValue, month.monthId]
                              : currentValue.filter((value: string) => value !== month.monthId);
                            field.onChange(newValue);
                          }}
                        />
                      }
                      label={month.name}
                    />
                  );
                }}
              />))}
          </FormGroup>

          {/*  required : true로 설정하면 null로 submit했을 때 해당 위치로 커서를 옮겨줍니다. */}
          <text>설명</text>
          <Box sx={{ backgroundColor: "white" }}>
            <Editor
              initialValue=" "
              previewStyle="vertical"
              height="400px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              toolbarItems={[
                ["heading"],
                ["scrollSync"],
              ]}
              theme="dark"
              usageStatistics={false}
              ref={editorContentRef}
            />
          </Box>

          <text>요약</text>
          <Box sx={{ backgroundColor: "white" }}>
            <Editor
              initialValue=" "
              previewStyle="vertical"
              height="400px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              toolbarItems={[
                ["heading"],
                ["scrollSync"],
              ]}
              theme="dark"
              usageStatistics={false}
              ref={editorSummaryRef}
            />
          </Box>

          <input
            {...register("files")}
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            ref={fileInputRef}
          />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {previewUrls.map((preview, index) => (
              <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                <img
                  src={preview.url}
                  alt={`Preview ${index + 1}`}
                  style={{ width: '100px', height: '100px' }}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>

          <button
            style={{
              backgroundColor: theme.btnColor,
              borderRadius: "10px",
              color: theme.backgroundColor,
              height: "30px"
            }}>추가
          </button>
        </form>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BirdList birds={fetchBirdData} />
      </div>
    </>
  );
}

export default BirdUploader;
