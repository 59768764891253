import { useLocation, useMatches, useNavigate } from "react-router-dom";
import styled from "styled-components";
import getPathHeader from "../../functions/getPathHeader";
import { Helmet } from "react-helmet";
import { Paragraph, Title } from "../../components/common/Text";
import * as React from "react";
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { theme } from "../../theme-colors/theme";

const RowContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 20px 0;
  gap: 10px;
`;

const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HorzontalLine = styled.hr`
  background-color: ${(props) => props.theme.btnColor};
  border: none;
  width: 100px;
  height: 3px;
`;

function Header() {
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundPosition: "center",
        backgroundColor: theme.lightColor,
        height: "300px",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Helmet>
        <title>{pathHeader}</title>
      </Helmet>
      <Typography variant="h3" fontFamily='MazzardM-Medium'
        sx={{
          color: theme.textColor,
        }}>
        {pathHeader}
      </Typography>
      <Paragraph style={{ color: theme.textColor, maxWidth: "1200px" }}>
        Find the Tours That Spark your mind
      </Paragraph>
    </Container>
  );
}
export default Header;
