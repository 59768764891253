import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./screens/Router";
// import { ThemeProvider } from "styled-components";



import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";


const queryClient = new QueryClient();





const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
