import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Card, Container, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";

import { HotelOutlined } from "@mui/icons-material";
import GoogleMapCard from "./body_right/GoogleMapCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "./components/Swiper.css";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Carousel from "react-material-ui-carousel";
import { useQuery } from "@tanstack/react-query";
import { ITour, getImagesByTourId, getTourById } from "../../../api/exploreApi";
import { useParams } from "react-router-dom";
import TourTab from "./components/TourTab";
import { readImageFromS3 } from "../../../functions/readImageFromS3";
import ReserveCard from "./ReserveCard";


function TourPost() {
  const { tourId } = useParams();

  const { isLoading: tourLoading, data: tour } = useQuery<ITour>({
    queryKey: [{ tourId }],
    queryFn: () => getTourById(tourId),
  });

  const { isLoading: birdLoading, data: tourBirds } = useQuery<string[]>({
    queryKey: ["topBirds" + tourId],
    queryFn: () => getImagesByTourId(tourId),
  });



  // Calendar
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>();
  if (tourLoading || birdLoading) {
    return <div>Loading...</div>; // 로딩 중일 때 처리
  }

  if (!tour) {
    return <div>No tour found</div>; // tour 데이터가 없을 때 처리
  }

  const details = [
    { title: "Location", content: "Republic of Korea" },
    { title: "Duration", content: `${tour?.duration} days` },
    { title: "Number of Species Expected", content: tour?.description.species },
    { title: "Tour Level", content: tour?.level },
  ];
  return (
    <Grid container rowGap="20px" paddingX='10%'>
      <Grid item xs={8} >
        {/* 제목 */}
        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" >
          <Box>
            <Typography variant="h4" align="left" fontFamily="MazzardM-Medium">
              {tour?.title}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="right">
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </Box>
        </Stack>
        {/* 사진, 개요 */}
        <Box my="20px">
          <Grid container alignItems="stretch" >
            <Grid xs={7} display='flex'
              justifyContent='center'
              alignItems='flex-end'
              paddingRight='2%'>
              <Swiper
                style={{
                  width: "600px",
                  height: "400px"
                }}
                modules={[Pagination, Navigation, Autoplay]}
                loop={true}
                pagination={{ el: '.swiper-pagination', type: 'progressbar' }}
                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                autoplay={{ delay: 2000 }}
              >
                {tourBirds?.map((img) => (
                  <SwiperSlide >
                    <Box
                      component="img"
                      sx={{
                        overflow: "hidden",
                        width: "600px",
                        height: "400px",
                        objectFit: "fill"
                      }}
                      src={readImageFromS3(img)}
                    ></Box>
                  </SwiperSlide>
                ))}

                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </Swiper>
            </Grid>
            <Grid xs={5} sx={{
              display: 'flex',
              justifyContent: 'center',  // 가로 방향 중앙 정렬
              alignItems: 'stretch',      // 세로 방향 중앙 정렬
            }}>
              <Grid container spacing={2} >
                {/* 기본 정보 출력 */}
                {details.map((item, index) => (
                  <Grid item xs={12} key={index} >
                    <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">
                      {item.title}
                    </Typography>
                    <Typography fontSize="16px" fontFamily="MazzardM-Medium">
                      {item.content}
                    </Typography>
                  </Grid>
                ))}

                {/* 마지막 두 개 항목을 가로로 배치 */}
                <Grid item xs={6}>
                  <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">
                    Group Size
                  </Typography>
                  <Typography fontSize="16px" fontFamily="MazzardM-Medium">
                    {tour?.size}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" color="#474747" fontFamily="MazzardM-Medium">
                    Tour Level
                  </Typography>
                  <Typography fontSize="16px" fontFamily="MazzardM-Medium">
                    {tour?.level}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* 탭 영역 */}
        <TourTab key={tour?.id} {...tour} />
      </Grid >
      <Grid item xs={4} >
        <ReserveCard key={tour?.id} {...tour} />
        {/* <Stack spacing={{ xs: 1, sm: 2 }} useFlexGap flexWrap="wrap" justifyContent='center' alignItems='center'>
          <Typography align="center">Schedule</Typography>
          <Typography align="center">{2} Departure Dates Available</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ListItemText
              sx={{ my: 0 }}
              primary="Date Range Picker"
              primaryTypographyProps={{
                fontSize: 20,
                fontWeight: "medium",
                letterSpacing: 0,
              }}
            />
            <ListItem sx={{
              width: 'auto'
            }}>
              <DatePicker
                label="From"
                value={startDate}
                onChange={(newDate) => setStartDate(newDate)}
              />
            </ListItem>
          </LocalizationProvider>
        </Stack> */}
      </Grid>
    </Grid >
  );
}
export default TourPost;
