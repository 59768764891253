import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { ITour, getTopBirdById } from "../../../../api/exploreApi";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import BirdCard from "./BirdCard";

export default function Overview(tour: ITour) {


    const { isLoading: birdLoading, data: topBirdData } = useQuery<string[]>({
        queryKey: ["topbirds"],
        queryFn: () => getTopBirdById(tour.id),
    });

    const details = [
        { title: "HABITS COVERED", content: tour.description.habits },
        { title: "EXPECTED CLIMATE", content: tour.description.climate },
        { title: "MAX GROUP SIZE", content: tour.size },
        { title: "TOUR PACE & WALKING", content: tour.description.pace },
        { title: "ACCOMODATION", content: tour.description.accommodation },
        { title: "EASE OF BIRDING", content: tour.description.ease },
        { title: "NUMBER OF SPECIES EXPECTED", content: tour.description.species },
        { title: "OTHER ATTRACTIONS", content: tour.description.others },
        { title: "PHOTOGRAPHIC OPPORTUNITIES", content: tour.description.photo },
    ];

    return <>
        <Box>
            <Typography mb={3} sx={{ whiteSpace: 'pre-line', textAlign: 'justify' }} fontFamily="MazzardM-Regular">
                {tour.description.overview}
            </Typography>
            <Typography variant="h6" fontFamily="MazzardM-Bold">
                Top Birds
            </Typography>
            {birdLoading ? null : (
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        py: 1,
                        marginBottom: 5,
                        width: "100%",
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        scrollSnapType: "x mandatory",
                        "& > *": {
                            scrollSnapAlign: "center",
                        },

                    }}
                >
                    {topBirdData?.map((topBirdId: string) => (
                        <BirdCard topBirdId={topBirdId} ></BirdCard>
                    ))}
                </Box>
            )}
            {details.map((item, index) => (
                <Box key={index} mb={3}>
                    <Typography variant="h6" fontFamily="MazzardM-Bold">
                        {item.title}
                    </Typography>
                    <Typography fontFamily="MazzardM-Regular">
                        {item.content}
                    </Typography>
                </Box>
            ))}
        </Box>
    </>
}