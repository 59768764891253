import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from '@mui/material/Typography';
import { theme } from "../../../theme-colors/theme";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

// icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ITour, getImagesByTourId } from "../../../api/exploreApi";
import { readImageFromS3 } from "../../../functions/readImageFromS3";
import { useQuery } from "@tanstack/react-query";

function TourCard(data: ITour) {

  const { isLoading: birdLoading, data: tourBirds } = useQuery<string[]>({
    queryKey: ["tourCard" + data.id],
    queryFn: () => getImagesByTourId(data.id),
  });


  const navigate = useNavigate();

  return (
    <Box
      sx={{
        overflow: { xs: "auto", sm: "initial", xl: "auto" },
      }}
    >
      <Divider
        sx={{
          border: 1,
          color: theme.grayColor
        }}
      />
      <Card
        orientation="horizontal"
        sx={{
          backgroundColor: theme.backgroundColor,
          flexWrap: "wrap",
          border: 0,
          my: 2
        }}
      >
        <AspectRatio flex maxHeight={250} sx={{ minWidth: 400 }}>

          <Box
            component="img"
            loading="eager"
            alt="no image"
            src={
              birdLoading
                ? "./store/logo_w.png"
                : readImageFromS3(tourBirds?.[0] || "./store/logo_w.png")
            }

            sx={{
              display: "flex",
              objectFit: "cover",
            }}
          />

        </AspectRatio>
        <CardContent>
          <Box sx={{
            display: "flex", flexDirection: "row",
            mx: 3,
            gap: 3
          }}>
            <Box flexGrow={2}>
              <Typography fontWeight="xl" fontFamily="MazzardM-Medium" onClick={() => {
                navigate(`/tour/${data.id}`);
              }}
                sx={{
                  fontSize: { xs: "15px", sm: "20px", xl: "25px" },
                  cursor: 'pointer'
                }}>
                {data.title}
              </Typography>

              <Box
                sx={{
                  my: 3,
                  flexDirection: "row",
                  display: "flex",
                  objectFit: "cover",
                  justifyContent: "space-between"
                }}>

                <Box display="flex" flexDirection="row" alignItems="center">
                  <IconButton aria-label="calendar">
                    <CalendarTodayIcon />
                  </IconButton>
                  <Typography fontWeight="md" fontFamily="MazzardM-Medium"
                    sx={{
                      fontSize: { xs: "12px", sm: "14px", xl: "16px" }
                    }}>
                    {data.duration} Days</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <IconButton aria-label="calendar">
                    <VisibilityIcon />
                  </IconButton>
                  <Typography fontWeight="md" fontFamily="MazzardM-Medium"
                    sx={{
                      fontSize: { xs: "12px", sm: "14px", xl: "16px" }
                    }}>
                    {data.description.species}+ Species</Typography>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography fontWeight="md" fontFamily="MazzardM-Medium"
                    sx={{
                      fontSize: { xs: "12px", sm: "14px", xl: "16px" }
                    }}>
                    4.9/5 (138 Reviews)</Typography>
                </Box>

              </Box>
              <Typography fontWeight="sm" maxHeight={100}
                fontFamily="MazzardM-Medium"
                sx={{
                  textOverflow: "ellipsis [...]",
                  overflow: "hidden",
                  fontSize: { xs: "12px", sm: "14px", xl: "16px" }
                }}>
                {data.description.overview}
              </Typography>
              <Typography fontWeight="lg" mt={1}>
                From ${data.price} / {data.dates.dates.length} Departure dates available
              </Typography>
            </Box>
            <Box flexGrow={1}
              sx={{
                justifyContent: "flex-end",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Box display="flex" justifyContent="right">
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </Box>
              <Button
                sx={{
                  color: theme.backgroundColor,
                  backgroundColor: theme.btnColor,
                  borderRadius: "30px",
                  width: "200px",
                  height: "40px"
                }}
                onClick={() => {
                  navigate(`/tour/${data.id}`);
                }}
              >
                Reservation
              </Button>
            </Box>
          </Box>

        </CardContent>
      </Card>
    </Box >
  );
}
export default TourCard;
